import axios from "axios";
import { Dialog, Notify } from "vant";
axios.defaults.withCredentials = true; //让ajax携带cookie
let http = {};
//当前真实生产环境:
let pro_url = 'https://proapi.sz-adtop.com/';
// 测试环境
let cs_url = "https://test.sz-adtop.com/";
// 春晖环境
let ch_url="https://zjapi.zh-chunhui.com/";
// 获取用户设备信息
let device_type = JSON.stringify({ "userAgent": navigator.userAgent, "platform": navigator.platform, "userLanguage": navigator.language })
const service = axios.create({
  baseURL:pro_url,
  // baseURL: cs_url,
  // baseURL: ch_url,
  timeout: 600000,
  validateStatus(status) {
    return status >= 200 && status < 300;
  }
});
service.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem("token");
    config.headers["token"] = token; // 让每个请求携带自定义 token 请根据实际情况自行修改
    config.headers["extInfo"] = device_type;
    return config;
  },
  error => {
    return Promise.reject();
  }
);
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject();
  }
);

http.get = function (url, data) {
  return new Promise((resolve, reject) => {
    service
      .get(url, data)
      .then(response => {
        if (response.rs == "0") {
          resolve(response.data);
        } else {
          reject(response.msg);
        }
      })
      .catch(e => {
        console.log(e);
      });
  });
};

http.post = function (url, data) {
  return new Promise((resolve, reject) => {
    data.userAgent = navigator.userAgent;
    var formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i])
    }
    service
      .post(url, formData)
      .then(response => {
        if (response.rs == "0") {
          resolve(response);
        }
        else if(response.rs=="113"){
          Dialog.alert({
            title: '',
            message: response.msg,
            confirmButtonText:'我知道了'
          }).then(() => {
            // on close
          });
          reject(response);
        }
        else {
          Notify({ type: "danger", message: response.msg, duration: 2000 });
          reject(response);
        }
      })
      .catch(e => {
        console.log(e);
      });
  });
};
window.alert = function (name) {
  var iframe = document.createElement("IFRAME");
  iframe.style.display = "none";
  document.documentElement.appendChild(iframe);
  window.frames[0].window.alert(name);
  iframe.parentNode.removeChild(iframe);
};
export default http;
